import React from 'react';
import { Tween } from 'react-gsap';

// import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
import low from '../assets/10percent.png';
import med from '../assets/15percent.png';
import high from '../assets/25percent.png';
// import sammich from '../assets/9_ItalianNightClub_F_Wide_flat2.png';

// import { ReactComponent as TWIcon } from '../assets/Twitter_icon.svg';
// import { ReactComponent as FBIcon } from '../assets/FB_icon.svg';
// import { ReactComponent as IGIcon } from '../assets/IG_icon.svg';
// import { ReactComponent as LIIcon } from '../assets/Link_icon.svg';

const med_level = 2000;
const high_level = 7000;

export default function PromoCode({nearest,elevation}) {
    const ft = elevation * 3.28084;
    let levelimg = low;
    let levelmsg = '';
    if (ft >= med_level ) {
        levelimg = med;
        levelmsg = '';
    }
    if (ft >= high_level ) {
        levelimg = high;
        levelmsg = '';
    }

    function orderOnline(){
        // window.open('')
    }

    return (
        <div className="vert-stack promo">
            <Tween from={{
                opacity:0, y:50, stagger:.2
            }}>
            <div className="boxed promo">
                <img src={levelimg} alt={levelmsg} />
            </div>
            
            <div className="nearest">
                {nearest && <>
                    <p>Nearest location</p>
                    <p className="address">{nearest}</p>
                </>}
            </div>

            <a className="button" id="redeem" onClick={orderOnline} href="https://online.jimmyjohns.com" target="_blank" rel="noreferrer">ORDER ONLINE</a>
            
            <div className="sammyfooter">
                <p className="legal">
                    Min spend: $10 subtotal before taxes and fees<br/>
                    <a href="https://www.jimmyjohns.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                    |
                    <a href={process.env.PUBLIC_URL + '/JJ_HowHighAreYou_TERMS_CONDITIONS.pdf'} target="_blank" rel="noreferrer">Terms And Conditions</a>
                </p>
            </div>
            </Tween>
        </div>
    );
}