import React, { useRef, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Tween } from 'react-gsap';

import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';

const acOptions = {
    fields: ['name','geometry','address_components'],
    types: ['(cities)'],
    componentRestrictions: { country:'US' }
}

export default function Welcome({submitAction,state,showError}) {
    const isFenced = state.step==='geofence';
    console.log('?',isFenced);
    // const focusAction = state==='allowgeo' ? requestAction : function(){};
    const textInput = useRef(null);
    const [error, setError] = useState(showError&&'Please enter a valid location');
    let autocomplete = null;

    const [queryObj, setQueryObj] = useState(state.location?{searchstring:state.location}:{}); 
    // if (state.location) queryObj.searchstring = state.location;


    function onLoad (_autocomplete) {
        // console.log('autocomplete: ', _autocomplete)
    
        autocomplete = _autocomplete;
    }
    
    function onPlaceChanged () {
        setError('');
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if(place.geometry){
                // store lat/lng if it exists.
                queryObj.location = place.geometry.location;
                const address = place.address_components;
                const state = address.find( ({types})=>types.includes('administrative_area_level_1') );
                // const city = address.find( ({types})=>types.includes('locality') );
                // queryObj.searchstring = place.name+', '+state.short_name;
                // console.log(queryObj,place.geometry.location);
                setQueryObj({location:place.geometry.location,searchstring:place.name+', '+state.short_name});
            } else {
                //queryObj.searchstring = place.name;
                setQueryObj({searchstring:place.name});
            }
            // console.log(autocomplete.getPlace())

        } else {
        //   console.log('Autocomplete is not loaded yet!')
        }
    }

    function onSubmit(){
        console.log('check',queryObj);
        if (!queryObj.searchstring){
            setQueryObj({searchstring:textInput.current.value});
            // queryObj.searchstring = textInput.current.value;
        }
        if (!queryObj.searchstring){
            setError('Please enter a valid location');
        } else {
            submitAction(queryObj);
        }
        
    }

    return (
        <div className="vert-stack">
            <Tween from={{
                opacity: 0,
                y: 50,
                stagger: .2,
                delay: .5
            }}>
 
            {/* normal welcome state */}
            <h1 className="welcome-h1">
                <img src={howhigh} alt="How high are you this 4/20" />
            </h1>

            {/* mobile display */}
            <p>
                Share your location and find out how high you are (in elevation, of course). The&nbsp;higher you are, the higher your&nbsp;discount.
            </p>

            <div>
                {state.location 
                ? <input type="text" placeholder="location" id="location" value={state.location} ref={textInput} readOnly />
                : <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                    options={acOptions}
                >
                    <input type="text" placeholder="location" id="location" ref={textInput} />
                </Autocomplete>
                }
                <span className="inputerror">{ error }</span>
            </div>

            <span className="button" id="submit" onClick={onSubmit.bind(this)}>Submit</span>

            </Tween>
        </div>
    );
}