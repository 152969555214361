import React, { useRef }  from 'react';
import { Tween } from 'react-gsap';
// import fileDownload from 'js-file-download';

// import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
import low from '../assets/JJ_420_You_are_a_little_bit_high_1024x512_v1.gif';
import med from '../assets/JJ_420_You_are_kinda_high_1024x512_v1.gif';
import high from '../assets/JJ_420_You_are_really_really_high_1024x512_v1.gif';
// import sammich from '../assets/9_ItalianNightClub_F_Wide_flat2.png';

import { ReactComponent as TWIcon } from '../assets/Twitter_icon.svg';
import { ReactComponent as FBIcon } from '../assets/FB_icon.svg';
import { ReactComponent as IGIcon } from '../assets/IG_icon.svg';
import { ReactComponent as LIIcon } from '../assets/Link_icon.svg';

// const axios = require('axios');
const med_level = 2000;
const high_level = 7000;

export default function Results({location,elevation,codeAction,sharePortrait,shareLandscape}) {

    const linkLI = useRef(null);
    const instaLI = useRef(null);

    const ft = elevation * 3.28084;
    let levelimg = low;
    let levelmsg = 'You are a little bit high.';
    let sharemsg = 'I’m a little bit high this 4/20.'
    if (ft >= med_level ) {
        levelimg = med;
        levelmsg = "You are kinda high!";
        sharemsg = 'I’m kinda high this 4/20.';
    }
    if (ft >= high_level ) {
        levelimg = high;
        levelmsg = "You are really really high!";
        sharemsg = 'I’m really, really high this 4/20.';
    }
    const baseurl = 'https://gethighwithjimmyjohns.com/'; // TODO. LAUNCH. change to final url
    function link(url){
        // window.open(url,'_blank');
        navigator.clipboard.writeText(baseurl);

        linkLI.current.classList.add('copied');
        setTimeout(()=>{ 
            linkLI.current.classList.remove('copied');
        },3000);
    }
    function download(url){
    //   axios.get(baseurl+url, { responseType: 'blob' })
    //   .then((res) => {
    //     fileDownload(res.data, 'jimmyjohns-share.jpg', 'image/jpg');
        instaLI.current.classList.add('downloaded');
        setTimeout(()=>{ 
            instaLI.current.classList.remove('downloaded');
        },3000);
    //   })
    //   .catch((error) => {
    //     alert(error);
    //   });
    }
    function share(platform,url){
        let citycode = url.split('/').pop();
        citycode = citycode.split('.')[0];
        const twsharemsg = `${sharemsg} How high are you? Find out and feed your munchies at ${baseurl}?${citycode} #GetHighWithJJs`;

        const shareLink = platform==='facebook' 
            ? `https://www.facebook.com/sharer/sharer.php?u=${baseurl}?${citycode}`
            : `https://twitter.com/intent/tweet?text=${encodeURIComponent(twsharemsg)}`;
        window.open(shareLink, "Jimmy John’s 4/20 - Social Share", "width=500,height=500");
    }
    return (
        <div className="vert-stack results">
            <Tween from={{
                opacity:0, y:50, stagger:.2, clearProps:'all'
            }}>
            <h4>
                4/20 Highness level
            </h4>
            <h1 className="highness">
                <img src={levelimg} alt={levelmsg} />
            </h1>
            <div className="boxed">
                <h2>{location}</h2>
                <h3>
                    {Math.abs(Math.floor(ft)).toLocaleString()} FT.
                    {ft>=0 && <span>Above sea level</span>}
                    {ft<0 && <span>Below sea level</span>}
                </h3>
            </div>
            <div className="share">
                <p>Share your results</p>
                <ul>
                    <li><TWIcon className="icon" onClick={()=>share('twitter',shareLandscape)} /></li>
                    <li><FBIcon className="icon" onClick={()=>share('facebook',shareLandscape)} /></li>
                    <li ref={instaLI}><a href={sharePortrait} download onClick={download}><IGIcon className="icon" /></a></li>
                    <li ref={linkLI}><LIIcon className="icon" onClick={()=>link(shareLandscape)} /></li>
                </ul>
            </div>
            <span className="button" id="redeem" onClick={codeAction}>Redeem Code</span>
            </Tween>
        </div>
    );
}