import React from 'react';

// import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
// import qr from '../assets/qrcode.png';
// import sammich from '../assets/9_ItalianNightClub_F_Wide_flat2.png';

export default function ThankYou() {
    return (
        <div className="vert-stack thankyou">
            <div className="boxed error">
                <h2>Thanks for sharing<br />your highness<br />with us this 4/20.</h2>
                <h2>Satisfy the<br />munchies any day at<br /><a href="https://www.jimmyjohns.com">jimmyjohns.com</a></h2>
            </div>
            <div className="sammyfooter"></div>
        </div>
    );
}