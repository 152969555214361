import React from 'react';

// import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
// import qr from '../assets/qrcode.png';
// import sammich from '../assets/9_ItalianNightClub_F_Wide_flat2.png';

export default function ComeBack() {
    return (
        <div className="vert-stack comeback">
            <div className="comeback-stack">
                <h2>Come</h2>
                <h2>Back</h2>
                <h2>On 4/20</h2>
            </div>
            <div className="sammyfooter"></div>
        </div>
    );
}