import React from 'react';

// import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
// import qr from '../assets/qrcode.png';

export default function Error() {
    return (
        <div className="vert-stack">
            <div className="boxed error">
                <h1>SORRY,</h1>
                <p>Looks like you're not located in the USA. This promo is for locations in the USA only.</p>
            </div>
        </div>
    );
}