import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
// import ReactGA from 'react-ga';
import { LoadScript } from '@react-google-maps/api';
import { Tween } from 'react-gsap';

import './App.scss';

import jj_logo from './assets/JJ Logo.png';

import Welcome from './components/Welcome';
import Calculating from './components/Calculating';
import Results from './components/Results';
import PromoCode from './components/PromoCode';
import Error from './components/Error';
import Desktop from './components/Desktop';
import ThankYou from './components/ThankYou';
import ComeBack from './components/ComeBack';

const axios = require('axios');
const api_key = 'AIzaSyC50Iy44TouvfdHVwZ9RrwLMsCp4N_suKQ';
const glibs = ['places'];

// ReactGA.initialize('G-Y22576PEDG');

class App extends Component {
// function App(){
  constructor(props){
    super(props);

    this.state = { step:"session" };

    this.getElevation = this.getElevation.bind(this);
    
  }

  initMapsApi(){
    console.log('init maps api',this.state.step);
    this.geocoder = new window.google.maps.Geocoder();
    this.elevator = new window.google.maps.ElevationService();
    this.places = new window.google.maps.places.PlacesService(document.createElement('div'));
    
    if (!this.state.skipMap) this.setSession(); // don't initialize on desktop
  }
  setSession(){
    // console.log('get pos', this.geoSuccess);
    // if (window.reactInit.loc.enabled){
      navigator.geolocation.getCurrentPosition(this.geoSuccess.bind(this), this.geoError.bind(this),{});
    // } else {
      // this.geoSuccess();
    // }
  }
  geoSuccess(position){
    const dlat = position.coords.latitude;
    const dlng = position.coords.longitude;

    this.geocoder.geocode( {location: {lat: parseFloat(dlat),lng: parseFloat(dlng)} })
      .then((resp)=>{
          console.log(resp);
          const firstresult = resp.results[0];
          const address = firstresult.address_components;
          const country = address.find( ({types})=>types.includes('country') );
          const state = address.find( ({types})=>types.includes('administrative_area_level_1') );
          const city = address.find( ({types})=>types.includes('locality') );
          // console.log(city.long_name,state.short_name,country.short_name);
          const newstate = {
            latitude:dlat,
            longitude:dlng,
            location:city.long_name+', '+state.short_name,
            country:country.short_name,
            step:'ready'
          };
          if (newstate.country!=='US') newstate.step = 'geofence'; 
          this.setState(newstate);
      })
      .catch((e)=> console.log('geocoder failed due to:'+e));
  }
  geoError(error){
    // console.log('geo error',error);
    this.setState({step:'allowgeo'});
  }
  resetState(){
    // console.log('reset state');
    this.setState({step:''});
    return false;
  }

  getLatLng(place){
    console.log(place);
    this.setState({step:'calculating'});
    window.document.body.classList.add('thinking');

    if (place.location) {
      this.setState({location:place.searchstring,latitude:place.location.lat(),longitude:place.location.lng()});
      this.getElevation(place.location);
      return;
    } 
      
    const placename = place.searchstring;
    console.log('get lat/lng',placename);

    this.geocoder.geocode( { address:placename } )
      .then((resp) => {
        console.log(resp,this.state.location);
        const loc = resp.results[0].geometry.location;
        if (!this.state.location){
          const firstresult = resp.results[0];
          const address = firstresult.address_components;
          const state = address.find( ({types})=>types.includes('administrative_area_level_1') );
          const city = address.find( ({types})=>types.includes('locality') );
          this.setState({location:city.long_name+', '+state.short_name,latitude:loc.lat(),longitude:loc.lng()});
        }

        this.getElevation(loc);
      })
      .catch((e)=> {
        console.log('geocoder failed due to:'+e);
        this.setState({step:'geoerror'});
      });
  }
  getElevation(loc){
    // console.log(res);
    // return;
    // const loc = res.data.results[0].geometry.location;
    console.log('get elevation',loc);
    this.elevator.getElevationForLocations( { locations: [loc] } )
      .then((resp) => {
        console.log(resp);
        const elevation = resp.results[0].elevation;
        // const loc = res.data.results[0].geometry.location;
        // this.getElevation(loc);
        this.setState({elevation:elevation});
        this.getShare();
      })
      .catch(function(err){
        console.log(err);
      });
  }

  getShare(){
    const ft = this.state.elevation * 3.28084;
    let path = 'https://gethighwithjimmyjohns.com/generate.php?';
      path += '&city='+this.state.location;
      path += '&elevation='+Math.floor(ft);
    axios.get(path)
      .then((res)=>{
        console.log(res);
        
        setTimeout(()=>{ 
          this.setState({step:'results',sharePortrait:res.data.portrait,shareLandscape:res.data.landscape})
        },2000);
      })
      .catch((err)=>{
        console.log('share err',err);
      });
  }

  getPromoCode(){
    const req = {
      query: "Jimmy John's",
      fields: ['name','formatted_address'],
      locationBias: {radius:50*1609.34, center: {lat:this.state.latitude,lng:this.state.longitude}}
    }
    // 50 mile radius
    
    this.places.findPlaceFromQuery(req,this.displayResults.bind(this));
  }
  displayResults(results,status){
    if (status===window.google.maps.places.PlacesServiceStatus.OK) {
      console.log(results);
      if (results[0]) {
        this.setState({step:'code',nearest:results[0].formatted_address});
      } else {
        this.setState({step:'code'});
      }
    } else {
      this.setState({step:'code'});
    }
  }

  componentDidMount(){

    // check if promo is over.
    const startdate = new Date(2022,3,20); 
    const overdate = new Date(2022,3,25); // set to first day that thank you message should be showing.
    const today = new Date();
    if (today<startdate){
      this.setState({step:'comeback',skipMap:true});
      return;
    } else if (today>overdate){
      this.setState({step:'over',skipMap:true});
      return;
    }

    // check if desktop.
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      // all android and iphones
    } else if (/Intel Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints>2){
      // ipad
    } else {
      this.setState({step:'desktop',skipMap:true});
    }

    
    
    // const isdev = false; // process.env.NODE_ENV === 'development';
    // if (isdev){ // skip session/geo handling on dev.
    //   this.setState( {step:'ready'} );
    //   this.initAjax();
    // } else {
    //   this.setSession();
    // }
  }

  render(){

    return (
      <div className="App">
        <LoadScript
          googleMapsApiKey={api_key}
          onLoad={this.initMapsApi.bind(this)}
          libraries={glibs} />
        
        <header className="App-header">
          <Tween from={{opacity:0,scale:.2}} ease="back.out(1.7)">
            <a href="https://www.jimmyjohns.com/" target="_blank" rel="noreferrer"><img src={jj_logo} alt="Jimmy John's Sandwiches" /></a>
          </Tween>
        </header>

        {/* GEOFENCE */}
        {this.state.step==='geofence' && <Error />}

        {/* PROMO OVER */}
        {this.state.step==='over' && <ThankYou />}

        {/* not started yet */}
        {this.state.step==='comeback' && <ComeBack />}

        {/* DESKTOP */}
        {this.state.step==='desktop' && <Desktop />}

        {/* READY STATE */}
        { (this.state.step==='allowgeo'||this.state.step==='ready'||this.state.step==='geoerror') && <Welcome submitAction={this.getLatLng.bind(this)} requestAction={this.setSession} state={this.state} showError={this.state.step==='geoerror'} />}

        {/* CALCULATING GRAPHIC */}
        {this.state.step==='calculating' && <Calculating/>}

        {/* RESULTS PAGE */}
        {this.state.step==='results' && <Results location={this.state.location} elevation={this.state.elevation} codeAction={this.getPromoCode.bind(this)} sharePortrait={this.state.sharePortrait} shareLandscape={this.state.shareLandscape} />}

        {/* PROMO CODE */}
        {this.state.step==='code' && <PromoCode nearest={this.state.nearest} elevation={this.state.elevation} />}

      </div>
    );
  }
}

export default App;
