import React from 'react';
import { Tween } from 'react-gsap';

// import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
// import qr from '../assets/qrcode.png';
// import smoke from '../assets/smoke.jpg';

export default function Calculating() {
    // const [dots, setDots] = useState(0);

    // useEffect(()=>{
    //     const interval = setInterval(() => {
    //         // console.log(dots);
    //         setDots(pdots => pdots+1);
    //     },100);
    //     return () => clearInterval(interval);
    // },[]);
    
    return (
        <div className="center-stack">
            <Tween from={{opacity:0,top:50}}>
            <h1>
                Calculating Highness
                <span className="dot1">.</span>
                <span className="dot2">.</span>
                <span className="dot3">.</span>
            </h1>
            <div className="smoke"/>
            </Tween>
        </div>
    );
}