import React from 'react';
import { Tween } from 'react-gsap';

import howhigh from '../assets/HOW HIGH ARE YOU TYPE.png';
import qr from '../assets/qr-code.svg';

import { ReactComponent as TWIcon } from '../assets/Twitter_icon.svg';
import { ReactComponent as FBIcon } from '../assets/FB_icon.svg';
import { ReactComponent as IGIcon } from '../assets/IG_icon.svg';


export default function Desktop() {

    return (
        <div className="vert-stack">
            <Tween from={{
                opacity: 0,
                y: 50,
                stagger: .2,
                delay: .5
            }}>
 
                {/* normal welcome state */}
                <h1>
                    <img src={howhigh} alt="How high are you this 4/20"/>
                </h1>

                {/* desktop display */}
                <p>
                    SCAN THE QR CODE WITH YOUR PHONE’S CAMERA TO CALCULATE YOUR HIGHNESS AND GET SOME SANDWICHES.
                </p>
                <img src={qr} alt="qrcode" className="qr"/>

                <div className="share social">
                    <ul>
                        <li><a href="https://twitter.com/jimmyjohns" target="_blank" rel="noreferrer"><TWIcon className="icon" /></a></li>
                        <li><a href="https://www.facebook.com/jimmyjohns/" target="_blank" rel="noreferrer"><FBIcon className="icon" /></a></li>
                        <li><a href="https://www.instagram.com/jimmyjohns/" target="_blank" rel="noreferrer"><IGIcon className="icon" /></a></li>
                    </ul>
                    <span>
                        <a href="https://www.jimmyjohns.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                        |
                        <a href={process.env.PUBLIC_URL + '/JJ_HowHighAreYou_TERMS_CONDITIONS.pdf'} target="_blank" rel="noreferrer">Terms And Conditions</a>
                    </span>
                </div>

            </Tween>
        </div>
    );
}